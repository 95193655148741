'use client';

import { createContext, useContext, useEffect, useState, useRef } from 'react';

import { z } from 'zod';
import { useAuth } from '@clerk/nextjs';
import { BillingDetails } from '@vocalstack/js-sdk';

import { getBrowserCookieValue } from '@/utils/cookies';

export type BillingCycle = 'Monthly' | 'Annually';

const zBillingUpdate = z.object({
  user: z.string(),
  date: z.number(),
});

type BillingContext = {
  billing?: BillingDetails;
  fetchBilling: (force?: boolean) => Promise<void>;
  premium_yearly_payment_link: string;
  premium_monthly_payment_link: string;
  billingCycle: BillingCycle;
  setBillingCycle: React.Dispatch<React.SetStateAction<BillingCycle>>;
  supportEmail?: string;
};

const BillingContext = createContext<BillingContext>({
  billing: undefined,
  fetchBilling: () => Promise.resolve(),
  premium_monthly_payment_link: '',
  premium_yearly_payment_link: '',
  billingCycle: 'Annually',
  setBillingCycle: () => {},
  supportEmail: '',
});

type BillingProviderProps = {
  children: React.ReactNode;
};

type PaymentLinks = {
  premium_yearly_payment_link: string;
  premium_monthly_payment_link: string;
};

export const BillingProvider = ({ children }: BillingProviderProps) => {
  const [_billing, setBilling] = useState<BillingDetails | undefined>();
  const [billingCycle, setBillingCycle] = useState<BillingCycle>('Annually');
  const [paymentLinks, setPaymentLinks] = useState<PaymentLinks | undefined>();
  const [supportEmail, setSupportEmail] = useState<string>();

  const hasFetched = useRef(false);

  const { isSignedIn } = useAuth();

  const fetchBilling = async (force?: boolean) => {
    try {
      const response = await fetch(`/api/billing${force ? '?force=true' : ''}`);
      if (!response.ok) {
        throw new Error(`Error fetching billing: ${response.statusText}`);
      }
      const json = await response.json();
      if (json.billing) {
        window.localStorage.setItem('billing', JSON.stringify(json));
        setBilling(json.billing);
        setPaymentLinks({
          premium_monthly_payment_link: json?.premium_monthly_payment_link,
          premium_yearly_payment_link: json?.premium_yearly_payment_link,
        });
        setSupportEmail(json?.support_email);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!isSignedIn) {
      window.localStorage.removeItem('billing');
      document.cookie = 'LAST_BILLING_UPDATE=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
      return;
    }

    // set from local storage
    const billingString = window.localStorage.getItem('billing');
    const json = billingString ? JSON.parse(billingString) : undefined;
    setBilling(json?.billing);
    setPaymentLinks({
      premium_monthly_payment_link: json?.premium_monthly_payment_link,
      premium_yearly_payment_link: json?.premium_yearly_payment_link,
    });
    setSupportEmail(json?.support_email);

    // see if needs update
    const forceUpdate = window.location.pathname.startsWith('/dashboard/billing');
    const lastBillingUpdate = zBillingUpdate.safeParse(
      JSON.parse(getBrowserCookieValue('LAST_BILLING_UPDATE') || '{}'),
    );
    const data = lastBillingUpdate.success ? lastBillingUpdate.data : undefined;

    if (forceUpdate || !data || !data?.date || data?.date < Date.now() - 86400000) {
      if (hasFetched.current) return;
      hasFetched.current = true;
      void fetchBilling(forceUpdate);
    }
  }, [isSignedIn]);

  return (
    <BillingContext.Provider
      value={{
        billing: _billing,
        fetchBilling,
        premium_monthly_payment_link: paymentLinks?.premium_monthly_payment_link || '',
        premium_yearly_payment_link: paymentLinks?.premium_yearly_payment_link || '',
        billingCycle,
        setBillingCycle,
        supportEmail,
      }}
    >
      {children}
    </BillingContext.Provider>
  );
};

export function useBilling() {
  return useContext(BillingContext);
}
