'use client';

import { useEffect, useState } from 'react';

import Script from 'next/script';
import { useTheme } from 'next-themes';
import { useUser } from '@clerk/clerk-react';

import { LocaleKey } from '@/constants/locales.js';

if (typeof window !== 'undefined') {
  require('./sdk.js');
}

type ChatwootProps = {
  type: 'support' | 'questions';
  localeKey: LocaleKey;
  userPlan?: 'hobby' | 'premium' | 'enterprise';
  isDev?: boolean;
};

// TODO: fix bug where "Questions" chatwoot is open in non-signed in page, then user signs in and "Questions" is still open instead of "Support" (until page refresh)

export const Chatwoot = (props: ChatwootProps) => {
  const { type, localeKey, userPlan, isDev } = props;
  const { theme } = useTheme();
  const { isSignedIn, user } = useUser();
  const [token, setToken] = useState<string | undefined>();
  const [setup, setSetup] = useState<string | undefined>();

  const setupChatwoot = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).chatwootSettings = {
      hideMessageBubble: false,
      position: 'right',
      locale: 'en',
      type: 'standard',
    };

    const token =
      type === 'questions' ? 'a2DUbyRZUbprhaGi27DH6WvT' : type === 'support' ? 'dTR61xJvRBLREzUSARCDchHj' : undefined;

    const userData = deleteUndefined({
      avatar_url: isSignedIn ? user.imageUrl : undefined,
      email: isSignedIn ? user.emailAddresses[0].emailAddress : undefined,
      name: isSignedIn ? `${user.firstName} ${user.lastName}` : undefined,
    });

    const customAttributes = deleteUndefined({ userPlan, fullName: userData?.name, emailAddress: userData?.email });

    const userId = isSignedIn ? user.id : undefined;

    const setup = `
      window.$chatwoot.darkMode = "${theme === 'dark' ? 'dark' : 'light'}";
      window.$chatwoot.locale = "${localeKey}";
      Object.keys(${JSON.stringify(customAttributes)}).length && window.$chatwoot.setCustomAttributes(${JSON.stringify(customAttributes)});
      Object.keys(${JSON.stringify(userData)}).length && window.$chatwoot.setUser("${userId}", ${JSON.stringify(userData)});
    `;

    setToken(token);
    setSetup(setup);
  };

  useEffect(() => {
    if (isDev) {
      return;
    }
    if (typeof isSignedIn !== 'undefined') {
      setupChatwoot();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn]);

  if (isDev) {
    return null;
  }

  return token && setup ? (
    <Script id="chatwoot">
      {`
        window.chatwootSDK.run({
          websiteToken: '${token}',
          baseUrl: "https://app.chatwoot.com"
        })
        ${setup}
        setTimeout(() => {${setup}});
        setTimeout(() => {${setup}}, 1000);
    `}
    </Script>
  ) : null;
};

const deleteUndefined = (obj: Record<string, unknown>) => {
  for (const key in obj) {
    if (obj[key as keyof typeof obj] === undefined) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete obj[key as keyof typeof obj];
    }
  }
  return obj;
};

// (function(d,t) {
//   var BASE_URL="https://app.chatwoot.com";
//   var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
//   g.src=BASE_URL+"/packs/js/sdk.js";
//   g.defer = true;
//   g.async = true;
//   s.parentNode.insertBefore(g,s);
//   g.onload=function(){
//     window.chatwootSDK.run({
//       websiteToken: 'dTR61xJvRBLREzUSARCDchHj',
//       baseUrl: BASE_URL
//     })
//   }
// })(document,"script");
