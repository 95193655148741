'use client';
import { useState, useEffect } from 'react';

import { useTheme } from 'next-themes';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import classNames from 'classnames';

import { colors } from '@/constants/tailwindColors';

type DarkModeProps = {
  className?: string;
  hasDarkThemeCookie?: boolean;
  sunColor?: string;
  moonColor?: string;
};

export const DarkModeIcon = ({
  className,
  hasDarkThemeCookie,
  sunColor = colors.accent[500],
  moonColor = colors.primary[200],
}: DarkModeProps) => {
  const [mounted, setMounted] = useState(false);
  const { theme, setTheme } = useTheme();

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (theme === 'system') {
      return setTheme(window.matchMedia('(prefers-color-scheme: dark)')?.matches ? 'dark' : 'light');
    }
    document.cookie = `DARK_MODE_COOKIE=${theme}; path=/; expires=Fri, 31 Dec 2100 23:59:59 GMT`;
  }, [setTheme, theme]);

  return (
    <div
      className={classNames(
        'flex h-full cursor-pointer items-center p-3 opacity-70 transition-opacity hover:opacity-100',
        className,
      )}
      onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
    >
      {mounted ? (
        <DarkModeSwitch
          checked={theme === 'dark'}
          onChange={() => undefined}
          sunColor={sunColor}
          moonColor={moonColor}
          size="20px"
        />
      ) : (
        <DarkModeSwitch
          checked={Boolean(hasDarkThemeCookie)}
          onChange={() => undefined}
          sunColor={sunColor}
          moonColor={moonColor}
          size="20px"
        />
      )}
    </div>
  );
};
