'use client';

import React, { ReactNode } from 'react';

import { renderToStaticMarkup } from 'react-dom/server';

import { useTranslation } from '@/hooks/useTranslation';
import { LocaleKey } from '@/constants/locales';

type Text = string | string[] | ReactNode;

export type TranslationOverwrites = Partial<Record<LocaleKey, string>>;

export type TranslationProps = {
  enabled?: boolean;
  name: string;
  children: Text;
  nonDefaultLocaleText?: Text;
  namespace?: 'main' | 'dashboard';
  overwrites?: TranslationOverwrites;
  className?: string;
};

export const Translation = ({
  enabled,
  name,
  children,
  nonDefaultLocaleText,
  namespace,
  overwrites,
  className,
}: TranslationProps) => {
  if (typeof enabled !== 'undefined' && !enabled) {
    return <span className={className}>{children}</span>;
  }

  const stringified = stringifyText(children);
  const key = name.split('.');
  if (key.some((k) => k.includes('.'))) {
    console.log({ name, children, key });
    throw Error('Key item should not contain a period');
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const translation = useTranslation(key, stringified, {
    nonDefaultLocaleText: typeof nonDefaultLocaleText !== 'undefined' ? stringifyText(nonDefaultLocaleText) : undefined,
    namespace,
    overwrites,
  });

  const containsReactElement = (node: ReactNode): boolean => {
    if (React.isValidElement(node)) {
      return true;
    }
    if (Array.isArray(node)) {
      return node.some((child) => containsReactElement(child));
    }
    return false;
  };

  const returnsMarkup = containsReactElement(children);

  return returnsMarkup ? (
    <span className={className} suppressHydrationWarning dangerouslySetInnerHTML={{ __html: translation }} />
  ) : (
    <span className={className} suppressHydrationWarning>
      {translation}
    </span>
  );
};

const stringifyText = (text?: ReactNode): string => {
  if (typeof text === 'string') {
    return text;
  }
  if (Array.isArray(text)) {
    return text.map((child) => stringifyText(child)).join('');
  }
  if (React.isValidElement(text)) {
    return renderToStaticMarkup(text);
  }
  if (!text) {
    return '';
  }
  return String(text);
};
