/* eslint-disable @typescript-eslint/no-explicit-any */
import { LocaleKey } from '@/constants/locales';
import { useGlobal } from '@/context/GlobalContext';
import { TranslationOptions } from '@/utils/translations';

export const useTranslation = (key: string[], defaultLocaleText: string, options?: TranslationOptions) => {
  const { localeKey } = useGlobal();

  return useLocaleSpecificTranslation(localeKey, key, defaultLocaleText, options);
};

export const useLocaleSpecificTranslation = (
  localeKey: LocaleKey,
  key: string[],
  defaultLocaleText: string,
  options?: TranslationOptions,
) => {
  const windowKey = key.join('.');
  if (typeof window !== 'undefined') {
    return (window as any).translations?.[windowKey] || defaultLocaleText || windowKey;

    // else statement is needed for NextJS static analysis by the bundler
    // otherwise top-level require will be executed and the module will be included in the bundle
  } else {
    const Translations = require('@/utils/translations').Translations;
    const translations = new Translations(options?.namespace || 'main');
    const translation = translations.getTranslation(localeKey, key, defaultLocaleText.trim(), options);

    const transformedTranslation =
      defaultLocaleText.at(-1) !== '.' && translation.at(-1) === '.' ? translation.slice(0, -1) : translation;

    return transformedTranslation;
  }
};
