'use client';

/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from 'react';

import Image from 'next/image';
import classNames from 'classnames';
import { UserIcon } from '@heroicons/react/24/outline';
import { SignedIn, SignedOut, UserButton, SignInButton, useAuth, ClerkLoading } from '@clerk/nextjs';

import { LanguageSelector } from './LanguageSelector';
import { DarkModeIcon } from './DarkModeIcon';

import { Caret } from '@/components/icons/icons';
import { LOCALE_COOKIE, LocaleKey, locales } from '@/constants/locales';
import { Anchor } from '@/components/Fields/Buttons';
import { Translation } from '@/components/Translation';
import { CountryFlag } from '@/components/widgets/CountryFlag/CountryFlag';

type SecondaryNavigationProps = {
  showDashboardButton?: boolean;
  localeKey: LocaleKey;
  hasDarkThemeCookie: boolean;
  className?: string;
};

// TODO: translations

export const SecondaryNavigation = ({
  showDashboardButton,
  localeKey,
  hasDarkThemeCookie,
  className,
}: SecondaryNavigationProps) => {
  const { isSignedIn } = useAuth();
  const [showUserButton, setShowUserButton] = useState(false);
  const [userBtn, setUserBtn] = useState<React.ReactNode>(null);

  const [isIsLanguagesModalOpen, setIsLanguagesModalOpen] = useState(false);
  const locale = locales.find(({ id }) => id === localeKey);

  useEffect(() => {
    if (isSignedIn) {
      setUserBtn(
        <UserButton afterSignOutUrl="/">
          <UserButton.MenuItems>
            <UserButton.Action
              labelIcon={
                <div className="flex w-[300px] flex-row items-center gap-6">
                  <CountryFlag code={locale?.country ?? 'en'} />
                  <span>
                    {String(locale?.actualTitle) ?? 'Select Language'}
                    <Caret className="ml-1.5 inline h-2.5 w-2.5 opacity-50" />
                  </span>
                </div>
              }
              label=" "
              onClick={() => setIsLanguagesModalOpen(true)}
            />
          </UserButton.MenuItems>
        </UserButton>,
      );
      setShowUserButton(true);
    } else {
      setShowUserButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn]);

  const onLanguageChange = (localeKey: LocaleKey) => {
    document.cookie = `${LOCALE_COOKIE}=${localeKey}; path=/; expires=Fri, 31 Dec 2100 23:59:59 GMT`;
    window.location.reload();
  };

  const signedInContent = (
    <>
      {showDashboardButton && (
        <Anchor
          className="mr-4 bg-primary-100 hover:bg-primary-200/60 dark:bg-primary-500 dark:text-white dark:hover:bg-primary-500/90"
          href="/dashboard"
          suppressHydrationWarning
        >
          Dashboard
        </Anchor>
      )}
      {isIsLanguagesModalOpen && (
        <LanguageSelector
          localeKey={localeKey}
          onLanguageChange={onLanguageChange}
          place="bottom-end"
          anchor=".language-nav-anchor"
          redirectOnClick={!window.location.pathname.startsWith('/dashboard')}
          updateUserLocale
          open
        />
      )}
      <div className="language-nav-anchor mx-2 w-8 scale-[60%]" suppressHydrationWarning>
        <span
          className={classNames({ 'pointer-events-none absolute touch-none opacity-0': !showUserButton })}
          suppressHydrationWarning
        >
          {userBtn}
        </span>
        {isSignedIn && !showUserButton && (
          <div className="h-8 w-8" suppressHydrationWarning>
            <Image
              src="/avatar.png"
              alt="avatar"
              width={32}
              height={32}
              className="h-full w-full rounded-full"
              suppressHydrationWarning
            />
          </div>
        )}
      </div>
    </>
  );

  const signedOutContent = (
    <button
      className="flex cursor-pointer items-center gap-2 rounded-lg px-3 py-2 text-base font-semibold leading-6 underline-offset-8 hover:bg-primary-500/5 dark:decoration-white/20 dark:hover:bg-white/5"
      suppressHydrationWarning
    >
      <UserIcon className="h-4" suppressHydrationWarning />
      <div className="whitespace-nowrap" suppressHydrationWarning>
        <Translation name="nav.item.sign-in">Sign in</Translation>
      </div>
    </button>
  );

  const languages = (
    <LanguageSelector localeKey={localeKey} onLanguageChange={onLanguageChange} redirectOnClick place="bottom-end" />
  );

  return (
    <div
      className={classNames('-mr-4 flex items-center justify-end gap-2 pdf:!hidden print:!hidden', className)}
      suppressHydrationWarning
    >
      <DarkModeIcon hasDarkThemeCookie={hasDarkThemeCookie} />

      <SignedOut>{languages}</SignedOut>

      <SignedIn>{signedInContent}</SignedIn>

      <SignedOut>
        <SignInButton mode="modal">{signedOutContent}</SignInButton>
      </SignedOut>

      <ClerkLoading>{!isSignedIn && signedOutContent}</ClerkLoading>
    </div>
  );
};
