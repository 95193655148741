import React, { HTMLAttributes } from 'react';

import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

const css = classNames('flex gap-2 lg:gap-3', '[&_svg]:shrink-0 [&_svg]:grow-0', 'lt-md:text-center sm:text-balance');

export const textToId = (text: string) =>
  text
    .toLowerCase()
    .replace(/[^a-z0-9]/g, '-')
    .replace(/-+/g, '-')
    .replace(/^-|-$/g, '');

export type HeadingCustomProps = {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  hash?: boolean;
};

// Add this function to extract text from children
function getTextFromChildren(children: React.ReactNode): string {
  let text = '';
  React.Children.forEach(children, (child) => {
    if (typeof child === 'string' || typeof child === 'number') {
      text += child;
    } else if (React.isValidElement(child) && child.props.children) {
      text += getTextFromChildren(child.props.children);
    }
  });
  return text;
}

export const Heading = (props: HTMLAttributes<HTMLHeadingElement> & HeadingCustomProps) => {
  const level = props.level >= 1 && props.level <= 6 ? props.level : 1;
  const Tag = `h${level}` as keyof JSX.IntrinsicElements;

  // Use the function to get text content
  const textContent = getTextFromChildren(props.children);
  const id = typeof props.children !== 'undefined' && textContent ? textToId(textContent) : undefined;

  return React.createElement(
    Tag,
    {
      ...props,
      className: twMerge(
        classNames('font-semibold', 'text-balance', {
          'text-2xl sm:text-3xl lg:text-4xl': level === 1 || level === 2,
          'text-lg sm:text-xl lg:text-2xl': level === 3,
          'text-md sm:text-lg lg:text-xl': level === 4,
          'text-md lg:text-lg': level === 5,
          'text-md': level === 6,
          'before:content-["#"] before:absolute before:h-full relative before:flex before:items-center before:-translate-x-full before:-ml-2 before:opacity-0 pointer hover:before:opacity-50 before:transition-opacity cursor-pointer':
            props.hash && id,
        }),
        css,
        props.className,
      ),
      id: props.hash ? undefined : props.id || id || undefined,
    },
    props.hash ? (
      <a className="!no-underline" href={`#${id}`} id={props.id || id || undefined}>
        {props.children}
      </a>
    ) : (
      props.children
    ),
  );
};
